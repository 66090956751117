<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col sm="12" style="position: relative;">
        <v-list two-line subheader color="grey lighten-5">
          <div class="loading" v-if="loading">
            <img width="80px" max-width="80px" src="../assets/loading.gif" />
          </div>
          <div v-if="message.text != null"></div>
          <div v-else>
            <v-list-item-group>
              <order
                v-for="order in currentOrders"
                :key="order.movement_id"
                :order="order"
              />
            </v-list-item-group>
          </div>
        </v-list>
      </v-col>
    </v-row>
    <v-layout row v-if="message.text">
      <v-flex xs12 sm6 px-1 ma-0>
        <app-alert
          :textAlert="message.text"
          :typeAlert="message.type"
          @dismissed="onDismissed()"
        ></app-alert>
      </v-flex>
    </v-layout>    
  </v-container>
</template>

<script>
import order from "./order";

export default {
  name: "Orders",
  mounted() {
    this.$store.dispatch("loadCurrentOrders");
    this.$store.dispatch(
      "setDefaultChassis",
      null
    ); 
    this.$store.dispatch(
      "setWeight",
      null
    );    
    this.$store.dispatch(
      "setSealNo",
      null
    );
    this.$store.dispatch(
      "setBOLNo",
      null
    );    
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
    loading() {
      return this.$store.getters.loading;
    },
    currentOrders() {
      return this.$store.getters.currentOrders;
    },
  },
  methods: {
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },
  },
  components: {
    order,
  },
  data: () => ({}),
};
</script>
<style scoped>
.loading {
  background: #ffffff;
  text-align: center;
  height: auto;
  padding: 65% 0;
}
</style>
