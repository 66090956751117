<template>
  <div>
    <template>
      <v-list-item class="item" @click="viewDetails">
        <v-list-item-content>
          <v-list-item-title class="my-1">
            <v-row wrap no-gutters>
              <v-col cols="4" sm="4">
                <div
                  class="grey--text text--darken-2 font-weight-bold subtitle-1"
                >
                  {{ order.waybill_id }}
                </div>
              </v-col>
              <v-col cols="8" sm="8">
                <div
                  class="orange--text text--darken-2 font-weight-bold subtitle-1"
                >
                  {{ order.details.work_assignment.appt_date }}
                  <span
                    class="orange--text text--darken-2 font-weight-bold subtitle-1"
                    v-if="order.details.work_assignment.appt_time != ''"
                    > / {{ order.details.work_assignment.appt_time }}</span
                  >
                  <span class="font-weight-bold subtitle-1" v-else></span>
                </div>
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-list-item-subtitle class="my-1">
            <v-row wrap no-gutters>
              <v-col cols="4" sm="4">{{
                order.details.route_information.dispatched_status
              }}</v-col>
              <v-col cols="8" sm="8">
                <div
                  v-for="container in order.details.load_information.containers"
                  :key="container.count"
                >
                  {{
                    container.type +
                      " " +
                      container.size +
                      " " +
                      container.number
                  }}
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="my-1">
            <v-row wrap no-gutters>
              <v-col cols="4" sm="4">FROM</v-col>
              <v-col cols="8" sm="8">{{
                order.details.route_information.move_from.name
              }}</v-col>
            </v-row>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="my-1">
            <v-row wrap no-gutters>
              <v-col cols="4" sm="4">TO</v-col>
              <v-col cols="8" sm="8">{{
                order.details.route_information.move_to.name
              }}</v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="centeritems justify-center">
          <v-btn icon>
            <v-icon
              large
              :class="[
                order.signature_required ? 'orange--text' : 'grey--text',
              ]"
              >{{
                order.signature_required
                  ? "mdi-signature-freehand"
                  : "mdi-information-variant"
              }}</v-icon
            >
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </div>
</template>

<script>
export default {
  name: "Order",
  props: ["order"],
  methods: {
    viewDetails() {
      //view details
      this.$router.push("./order-details/" + this.order.movement_id);
    },
  },
  components: {},
  data: () => ({}),
};
</script>

<style scoped>
.item {
  padding: 0 20px;
  background: #f7f7f7;
}
.centeritems {
  margin: 20px 0px 15px 10px !important;
  width: 50px;
}
</style>
